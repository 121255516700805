import axios from 'axios'
import authClient from './auth'

/*TEST ENV */
const BASE_URL = 'https://sbexpressbackend.azurewebsites.net/'
// const BASE_URL = 'https://sbexpressbackend-dev.azurewebsites.net/'

/**
 * Create a new axios client instance
 */
const getClient = (baseUrl = BASE_URL) => {
  const options = {
    baseURL: baseUrl,
  }

  if (localStorage.getItem('access_token')) {
    options.headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    }
  }

  const client = axios.create(options)

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        if (localStorage.getItem('refresh_token')) {
          await authClient.refreshToken()
          error.config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('access_token')
          return client(error.config)
        }
      }
      return Promise.reject(error)
    },
  )

  return client
}

/**
 * Base HTTP Client
 */
// eslint-disable-next-line
export default {
  // for creating a function and export the function names would cause reserved name problem - delete is a reserved word to be a function name
  // Provide request methods with the default base_url
  get: (url, conf = {}) => {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        // TODO: Place wrapper function on top to handle error messages
        // TODO: Move to
        if (error.response) {
          error.message = error.response.data
          console.error(error.response.data)
        } else {
          // TODO: Sort by response code and
          error.message = 'Unknown Error'
          console.error(error.message, error)
        }
        return Promise.reject(error)
      })
  },

  delete: (url, conf = {}) => {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  head: (url, conf = {}) => {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  options: (url, conf = {}) => {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  post: (url, data = {}, conf = {}) => {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        // TODO: Place wrapper function on top to handle error messages
        // TODO: Move to
        if (error.response) {
          error.message = error.response.data
        } else {
          // TODO: Sort by response code and
          error.message = 'Unknown Error'
        }
        return Promise.reject(error)
      })
  },

  put: (url, data = {}, conf = {}) => {
    return getClient()
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  patch: (url, data = {}, conf = {}) => {
    return getClient()
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  baseUrl: BASE_URL,
}
